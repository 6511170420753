import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

import lab_members from "../../json/lab_members.json";
import PeopleList from "./PeopleList.js";
const current_members = lab_members.current_members;
const alumni = lab_members.alumni;

class People extends Component {
  render() {
    return (
      <Container>
        <br />
        <Row>
          <Col>
            <h3>Lab Members</h3>
            <p>
              The VPNL is composed of outstanding researchers spanning
              psychology, neuroscience, computer science, cognitive science, and
              engineering. Interested in joining? Take a look at{" "}
              <Link to="/ProspectiveMembers">
                our information page for prospective lab members!
              </Link>
            </p>
            <PeopleList title="Current Members" members={current_members} />
            <PeopleList title="Lab Alumni" members={alumni} />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default People;
