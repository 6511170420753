import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

import "./PeopleList.css";
import PeopleCard from "./PeopleCard.js";

class PeopleList extends Component {
  constructor(props) {
    super(props);
    this.members = props.members;
    this.title = props.title;
  }

  render_members = members => {
    return members.map(member => {
      let default_open = false;
      if (
        member.name === "Other Lab Alumni" ||
        member.name === "Undergraduate Alumni"
      ) {
        default_open = true;
      }

      return (
        <Col key={member.name} lg="3" xs="6">
          <PeopleCard member={member} default_open={default_open} />
        </Col>
      );
    });
  };

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <h4>{this.title}</h4>
          </Col>
        </Row>
        <Row>{this.render_members(this.members)}</Row>
      </Container>
    );
  }
}

export default PeopleList;
