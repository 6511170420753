import React, { Component, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
  Card,
  CardHeader,
  CardBody
} from "reactstrap";

import ResearchCard from "./ResearchCard";

import "./Research.css";

import projects_json from "../../json/projects.json";
const projects = projects_json.projects;
const display_projects = projects_json.display_projects;

class Research extends Component {
  render_projects = projects => {
    return projects.map(project => {
      return <ResearchCard key={project.name} project={project} />;
    });
  };

  render_display_projects = display_projects => {
    return display_projects.map(display_project => {
      let img_display;
      if (display_project.img_href === "") {
        img_display = (
          <img
            src={display_project.img_src}
            alt={display_project.img_alt}
            className="width100"
          />
        );
      } else {
        img_display = (
          <a
            href={display_project.img_href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={display_project.img_src}
              alt={display_project.img_alt}
              className="width100"
            />
          </a>
        );
      }
      return (
        <Fragment key={display_project.name}>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <strong>{display_project.name}</strong>
                </CardHeader>
                <CardBody>
                  <p>{display_project.description}</p>
                  {img_display}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br />
        </Fragment>
      );
    });
  };

  render() {
    return (
      <Container>
        <br />
        <Row>
          <Col lg="6" xs="12">
            <h3>Our Research</h3>
            <p>
              We utilize functional imaging (fMRI), quantitative MRI, diffusion
              weighted imaging (DWI), electrocarticography, computational
              techniques, and behavioral methods to investigate the neural basis
              of visual recognition and categorization. For humans, visual
              recognition is a natural, effortless skill that occurs within a
              few hundreds of milliseconds, yet, it is one of the least
              understood aspects of visual perception. We are interested in
              investigating the underlying representations and cortical
              mechanisms that subserve recognition, and relate between these
              neural processes and our visual perception of the world.
            </p>
          </Col>
          <Col lg="6" xs="12">
            <img
              src="img/research_image.jpg"
              alt="Researchers at an MRI machine"
              className="width100"
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs="6" lg="6">
            <h4>Participate in Research!</h4>
            {this.render_display_projects(display_projects)}
          </Col>
          <Col xs="6" lg="6">
            <h4>Research Projects</h4>
            <ListGroup>{this.render_projects(projects)}</ListGroup>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Research;
