import React, { Fragment, Component } from "react";
import {
  Button,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardFooter
} from "reactstrap";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import projects_json from "../../json/projects.json";
import ResearchButton from "./ResearchButton";
import _ from "lodash";

const projects = projects_json.projects;

class PeopleCard extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.member = props.member;

    this.state = {
      collapse: props.default_open
    };
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    // does this lab member have a project?
    const member_project = _.find(projects, { lead: this.member.name });
    const toggle_arrow = this.state.collapse ? <FaAngleUp /> : <FaAngleDown />;
    const toggle_text = this.state.collapse ? "Show less" : "Show more";

    let project_render;
    if (member_project === undefined) {
      project_render = null;
    } else {
      project_render = <ResearchButton project={member_project} />;
    }

    let colorClass = "";
    if (this.member.role.startsWith("Graduate Student")) {
      colorClass = "grad-student-color";
    } else if (this.member.role.startsWith("Postdoc")) {
      colorClass = "postdoc-color";
    } else if (this.member.role.startsWith("Lab Manager")) {
      colorClass = "lab-manager-color";
    } else if (this.member.role.startsWith("Lab Director")) {
      colorClass = "pi-color";
    } else if (this.member.role.startsWith("Research Associate")) {
      colorClass = "associate-color";
    }

    let website_button;
    if (this.member.website === "") {
      website_button = null;
    } else {
      website_button = (
        <a href={this.member.website} target="_blank" rel="noopener noreferrer">
          <Button color="secondary">Website</Button>
        </a>
      );
    }

    let card_footer = null;
    if (website_button && project_render) {
      card_footer = (
        <CardFooter>
          {website_button}
          {` `}
          {project_render}
        </CardFooter>
      );
    } else if (website_button) {
      card_footer = <CardFooter>{website_button}</CardFooter>;
    } else if (project_render) {
      card_footer = <CardFooter>{project_render}</CardFooter>;
    }

    let title = null;
    if (this.member.title) {
      title = ", " + this.member.title;
    }

    let img_render = null;
    if (this.member.img_src) {
      const member_img_src = require(`../../${this.member.img_src}`);
      img_render = (
        <Fragment>
          <img
            className="circle"
            alt={this.member.img_alt}
            src={member_img_src}
          />
          <br />
          <br />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Card>
          <CardHeader className={colorClass}>
            <strong>
              {this.member.name}
              {title}
            </strong>
            <br />
            <em>{this.member.role}</em>
          </CardHeader>
          <CardBody>
            {img_render}
            <Button
              color="link"
              style={{ whiteSpace: "normal" }}
              onClick={this.toggle}
            >
              {toggle_arrow}
              {toggle_text}
            </Button>
            <Collapse isOpen={this.state.collapse}>
              {this.member.blurb}
            </Collapse>
          </CardBody>
          {card_footer}
        </Card>
        <br />
      </Fragment>
    );
  }
}

export default PeopleCard;
