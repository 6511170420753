import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardImg,
  CardBody,
  CardHeader
} from "reactstrap";
import { Link } from "react-router-dom";

const cartoon_eyes_src = require(`../../img/participate/cartoon-eyes.jpg`);
const baby_src = require(`../../img/participate/baby.jpg`);
const kid_src = require(`../../img/participate/kid.jpg`);
const img_url = require(`../../img/participate/Qparking.jpg`);

class Participate extends Component {
  render() {
    return (
      <Container>
        <br />
        <Row>
          <Col>
            <header role="banner">
              {/*Description of why we're interested in devlopment*/}
              <h1>
                Help us learn how babies and kids see!{" "}
                <CardImg
                  style={{ width: "7%", height: "7%" }}
                  src={cartoon_eyes_src}
                />{" "}
              </h1>
              <p>
                We study how our brain processes what we look at and how we
                perceive the world around us. While there is already a lot of
                research in adults, we know much less about how these skills
                develop in infancy and childhood. We are looking for babies and
                children to join our cool research studies so we can learn more!
              </p>
            </header>
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg="6" xs="12">
            <Card outline color="success">
              <CardHeader>
                <h4>Baby Study</h4>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="7" xs="12">
                    <p>
                      The brain undergoes rapid development after birth. We are conducting a large study to investigate how the brain develops its visual functions in infancy. We use two different safe and non-invasive technologies, Magnetic Resonance Imaging (MRI) and Functional Magnetic Resonance Imaging (fMRI), to measure brain activity while infants sleep (MRI) and look at pictures (fMRI). Your participation in the study would consist of a practice session, an fMRI session to measure your baby's brain function, and an MRI session to measure the structure of your baby's brain.
                    </p>
                  </Col>
                  <Col lg="5" xs="12">
                    <CardImg src={baby_src} />
                  </Col>
                </Row>
                <Link to="/Participate/Babies">
                  <Button color="success" size="sm">
                    Interested? Learn More!
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6" xs="12">
            <Card outline color="primary">
              <CardHeader>
                <h4>Kids Study</h4>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="6" xs="12">
                    <p>
                      Our lab is looking for kids 5-11 years old to participate
                      in our study! If your child wants to get a picture of
                      their brain while playing fun games click on the link
                      below to learn more.
                      <br />
                      <br />
                    </p>
                  </Col>
                  <Col lg="6" xs="12">
                    <CardImg src={kid_src} />
                  </Col>
                </Row>
                <Link to="/Participate/Kids">
                  <Button color="primary" size="sm">
                    Interested? Learn More!
                  </Button>
                </Link>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg="12" xs="12">
            <Card>
              <CardHeader>
                <h5>Directions to the Lab</h5>
              </CardHeader>
              <CardBody className="text-justify">
                <Row>
                  <Col lg="6" xs="12">
                    <p>
                      Our studies are done at the Stanford Psychology Department
                      in Building 420. During normal business hours, please park
                      in any of the{" "}
                      <a
                        href={img_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        Q parking spots{" "}
                      </a>
                      around the Stanford Oval where we will be waiting to
                      provide you with a parking permit. On weekends, or
                      evenings after 6pm, feel free to park in any of the A
                      parking spots around the Oval.
                    </p>
                  </Col>
                  <Col lg="5" xs="12">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2583.9303059380895!2d-122.17204959095697!3d37.42872384859359!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fbb2a42e10a8d%3A0x45c78c947a2033fa!2sBuilding+420%2C+Jordan+Hall!5e0!3m2!1sen!2sus!4v1550185570942"
                      width="490"
                      height="175"
                      title="googlemap"
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Participate;
