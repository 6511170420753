import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Button
} from "reactstrap";

import KidsCarousel from "./KidsCarousel";
import json_file from "../../json/kids_faq_data.json";
const faq_data = json_file.faq_data;

class Kids extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      active_id: ""
    };
  }

  toggle(event) {
    const id_number = event.target.id.split("_")[1];
    var collapse_id = "collapse_" + id_number;
    if (this.state.active_id === collapse_id) {
      collapse_id = "";
    }
    this.setState({ active_id: collapse_id });
  }

  render() {
    const faq_render = faq_data.map((item, idx) => {
      const button_id = "button_" + idx;
      const collapse_id = "collapse_" + idx;
      return (
        <div key={item.question}>
          <Button
            outline
            color="info"
            onClick={this.toggle}
            className="text-left"
            block
            id={button_id}
            style={{ marginBottom: "1rem" }}
          >
            {item.question}
          </Button>
          <Collapse
            isOpen={collapse_id === this.state.active_id ? true : false}
          >
            <Card>
              <CardBody>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.answer
                  }}
                />
              </CardBody>
            </Card>
          </Collapse>
        </div>
      );
    });
    return (
      <Container>
        <Row>
          <Col lg="6" xs="12">
            <br />
            <Card outline color="primary">
              <CardHeader>
                <h5>Details about our Kids Study</h5>
              </CardHeader>
              <CardBody className="text-justify">
                <KidsCarousel />
                <br />
                <p>
                  Some regions of the brain seem to be hardwired from birth,
                  while others are shaped by your experiences well into
                  adulthood. We are conducting a large study to investigate how
                  these visual regions of the brain develop from childhood to
                  adulthood, as little is currently known.
                  <br /> We will use a variety of games and behavioral tasks as
                  well as functional magnetic resonance imaging (fMRI) to study
                  your child's brain development across several years. You can
                  find more detailed information on our study in the FAQ, or
                  feel free to{" "}
                  <a href="mailto: stanfordperception@stanford.edu">
                    contact us
                  </a>
                  . Help us learn how the brain works and come visit us in our
                  lab at Stanford!
                </p>

                <a
                  href="https://stanforduniversity.qualtrics.com/jfe/form/SV_0TXleP1zkyrsW3j"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    outline
                    color="primary"
                    style={{ marginBottom: "1rem" }}
                  >
                    Sign up!
                  </Button>
                </a>
              </CardBody>
            </Card>
          </Col>
          {/* <br /> */}
          <Col lg="6" xs="12">
            <br />
            <Card>
              <CardHeader>
                <h5>Frequently Asked Questions</h5>
              </CardHeader>
              <CardBody>{faq_render}</CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Kids;
