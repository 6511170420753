import React, { Component, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import publications_json from "../../json/publications.json";
import Fuse from "fuse.js";
import _ from "lodash";
import "./Publications.css";
const pubs = publications_json.pubs;
const topic_order = publications_json.topic_order;

class Publications extends Component {
  state = {
    query: "",
    sort_criteria: "year"
  };

  handleSearch = search_term => {
    this.setState({
      query: search_term
    });
  };

  trimPubs = pubs => {
    if (this.state.query === "") {
      return pubs;
    }
    var options = {
      shouldSort: true,
      threshold: 0.2,
      location: 0,
      distance: 5000,
      maxPatternLength: 32,
      minMatchCharLength: 4,
      keys: ["title", "authors", "journal_string"]
    };
    var fuse = new Fuse(pubs, options);
    return fuse.search(this.state.query);
  };

  render_authors = authors => {
    return authors.map((author, i) => {
      let to_render;
      if (i === authors.length - 1) {
        // last
        if (authors.length === 1) {
          to_render = (
            <span>
              {author}
              <br />
            </span>
          );
        } else {
          to_render = (
            <span>
              and {author}
              <br />
            </span>
          );
        }
      } else if (i === authors.length - 2) {
        // penultimate
        to_render = (
          <span>
            {author}
            {` `}
          </span>
        );
      } else {
        //all others
        to_render = (
          <span>
            {author},{` `}
          </span>
        );
      }
      return <span key={authors.tag + author}>{to_render}</span>;
    });
  };

  render_publications = pubs => {
    return pubs.map(publication => {
      publication.authors.tag = publication.title;
      return (
        <ListGroupItem key={publication.title}>
          <a href={publication.link} target="_blank" rel="noopener noreferrer">
            <strong>{publication.title}</strong>
          </a>
          <br />
          <p className="additional-info">
            {this.render_authors(publication.authors)}
            {publication.journal_string}
          </p>
        </ListGroupItem>
      );
    });
  };

  render_publications_by = (publications, key) => {
    if (publications.length === 0) {
      return <h5>No search results found.</h5>;
    }

    const key_array = publications.map(pub => {
      return pub[key];
    });

    // kind of funny syntax, but guarantees that "undefined" topics go last
    let unique_keys = _.sortBy(_.uniq(key_array), key => {
      return key;
    });

    if (key === "year") {
      unique_keys = _.reverse(unique_keys);
    } else if (key === "topic") {
      unique_keys = topic_order;
    }

    const mapped_keys = unique_keys.map(curr_key => {
      const matching_pubs = _.filter(publications, pub => {
        return pub[key] === curr_key;
      });

      // always sort matching pubs by year
      let sorted_pubs = _.sortBy(matching_pubs, "year");
      if (key === "topic") {
        sorted_pubs = _.reverse(sorted_pubs);
      }

      return (
        <Fragment key={curr_key}>
          <h3>{curr_key ? curr_key : "Other"}</h3>
          <ListGroup>{this.render_publications(sorted_pubs)}</ListGroup>
          <br />
        </Fragment>
      );
    });

    let n_matches = <h5>Found {publications.length} matches!</h5>;
    if (this.state.query === "") {
      n_matches = null;
    } else if (publications.length === 1) {
      n_matches = <h5>Found {publications.length} match!</h5>;
    }
    return (
      <Fragment>
        {n_matches}
        <hr />
        {mapped_keys}
      </Fragment>
    );
  };

  render() {
    return (
      <Container>
        <br />
        <Row>
          <Col>
            <h3>Publications</h3>
          </Col>
        </Row>
        <hr />

        <Row>
          <Col lg="4" xs="8">
            <Form>
              <FormGroup>
                <Label for="search_input">
                  Filter by author, year, title, or journal!
                </Label>
                <Input
                  type="text"
                  id="search_input"
                  onChange={e => this.handleSearch(`${e.target.value}`)}
                  placeholder="e.g., development"
                />
              </FormGroup>
              <FormGroup>
                <Label for="sort_by">Sort By:</Label>
                <Input
                  type="select"
                  id="sort_by"
                  onChange={e =>
                    this.setState({
                      sort_criteria: e.target.value.toLowerCase()
                    })
                  }
                  defaultValue="Year"
                >
                  <option>Year</option>
                  <option>Topic</option>
                </Input>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            {this.render_publications_by(
              this.trimPubs(pubs),
              this.state.sort_criteria
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Publications;
