import React, { Component } from "react";
import "./App.css";

import { HashRouter as Router, Route } from "react-router-dom";

import MenuBar from "./components/MenuBar/MenuBar";
import Home from "./components/Home/Home";
import People from "./components/People/People";
import Research from "./components/Research/Research";
import Publications from "./components/Publications/Publications";
import Resources from "./components/Resources/Resources";
import ProspectiveMembers from "./components/ProspectiveMembers/ProspectiveMembers";
import Participate from "./components/Participate/Participate";
import Babies from "./components/Participate/Babies";
import Kids from "./components/Participate/Kids";
import Footer from "./components/Footer/Footer";
import Information from "./components/Information/Information";

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Route path="/" component={MenuBar} />
          <Route exact path="/" component={Home} />
          <Route exact path="/People" component={People} />
          <Route exact path="/Research" component={Research} />
          <Route exact path="/Publications" component={Publications} />
          <Route exact path="/Resources" component={Resources} />
          <Route exact path="/Information" component={Information} />
          <Route
            exact
            path="/ProspectiveMembers"
            component={ProspectiveMembers}
          />
          <Route exact path="/Participate" component={Participate} />
          <Route exact path="/Participate/Babies" component={Babies} />
          <Route exact path="/Participate/Kids" component={Kids} />
          <Route path="/" component={Footer} />
        </div>
      </Router>
    );
  }
}

export default App;
