import React, { Component, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader
} from "reactstrap";

import resources_json from "../../json/resources.json";
const resource_groups = resources_json.groups;

class Resources extends Component {
  render_resources = resources => {
    return resources.map(resource => {
      return (
        <Col lg="4" xs="6">
          <Card>
            <CardHeader>
              <h5>{resource.name}</h5>
            </CardHeader>
            <CardBody>
              {resource.description}
              <br />
              <br />
              <a href={resource.url} target="_blank" rel="noopener noreferrer">
                <Button outline color="primary">
                  Link
                </Button>
              </a>
            </CardBody>
          </Card>
        </Col>
      );
    });
  };

  render_resource_groups = groups => {
    return groups.map(group => {
      return (
        <Fragment key={group.group_name}>
          <Row>
            <Col>
              <h4>{group.group_name}</h4>
            </Col>
          </Row>
          <Row>{this.render_resources(group.resources)}</Row>
          <br />
        </Fragment>
      );
    });
  };

  render() {
    return (
      <Container>
        <br />
        <Row>
          <Col>
            <h3> VPNL Resources </h3>
            <p>
              We believe science is done best when scientists share their tools,
              ideas, and methods. This page links to a variety of resources we
              have shared in recent years. We hope you find them useful!
            </p>
          </Col>
        </Row>
        {this.render_resource_groups(resource_groups)}
      </Container>
    );
  }
}

export default Resources;
