import React, { Component, Fragment } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

class ResearchButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
    this.project = props.project;
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    let img_render = null;
    if (this.project.img_src) {
      const img_src = require(`../../${this.project.img_src}`);
      img_render = (
        <Fragment>
          <hr />
          <img
            src={img_src}
            alt={this.project.img_caption}
            className="width75"
          />
          <p>
            <em>{this.project.img_caption}</em>
          </p>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Button color="primary" onClick={this.toggle}>
          Research
        </Button>
        <div>
          <Modal
            size="lg"
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggle}>{this.project.name}</ModalHeader>
            <ModalBody>
              <div
                dangerouslySetInnerHTML={{ __html: this.project.description }}
              />
              {img_render}
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={this.toggle}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </Fragment>
    );
  }
}

export default ResearchButton;
