import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardHeader,
  Collapse,
  Button
} from "reactstrap";

import json_file from "../../json/babies_faq_data.json";
import scrollToComponent from "react-scroll-to-component";

const flyer_src = require(`../../img/participate/ad_updated_515.png`);
const faq_data = json_file.faq_data;

class Babies extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      active_id: ""
    };
  }

  toggle(event) {
    const id_number = event.target.id.split("_")[1];
    var collapse_id = "collapse_" + id_number;
    if (this.state.active_id === collapse_id) {
      collapse_id = "";
    }
    this.setState({ active_id: collapse_id });
  }

  scroll_to_survey = e => {
    var options = {
      duration: 1000
    };
    scrollToComponent(this.survey_ref, options);
  };

  render() {
    const faq_render = faq_data.map((item, idx) => {
      const button_id = "button_" + idx;
      const collapse_id = "collapse_" + idx;
      return (
        <div key={item.question}>
          <Button
            outline
            color="info"
            onClick={this.toggle}
            className="text-left"
            block
            id={button_id}
            style={{ marginBottom: "1rem" }}
          >
            {item.question}
          </Button>
          <Collapse
            isOpen={collapse_id === this.state.active_id ? true : false}
          >
            <Card>
              <CardBody>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.answer
                  }}
                />
              </CardBody>
            </Card>
          </Collapse>
        </div>
      );
    });
    return (
      <Container>
        <Row>
          <Col lg="6" xs="12">
            <br />
            <Card outline color="success">
              <CardHeader>
                <h5>Details about our Baby Study</h5>
              </CardHeader>
              <CardBody>
                <CardImg src={flyer_src} />
                <br />
                <br />
                <Button
                  outline
                  color="danger"
                  onClick={this.scroll_to_survey}
                  style={{ marginBottom: "1rem" }}
                >
                  Sign up!
                </Button>
                <p>
                  <br />
                   The brain undergoes rapid development after birth. We are conducting a large study to investigate how the brain develops its functions during the first year of life. Your participation in the study would consist of a practice session, an MRI scan to measure the development of your baby's brain, and an fMRI scan to see how your baby's brain functions.
                  <br />
                  <br />
                  This is a great opportunity to learn about brain development!
                  You will be financially compensated for your time and you will
                  get a picture of your baby’s brain. If you have further
                  questions, have a look at the FAQ or{" "}
                  <a href="mailto: babybrains@stanford.edu">contact us </a>
                  directly! You want to join?{" "}
                  <Button color="link" onClick={this.scroll_to_survey}>
                  Sign up
                  </Button>{" "}
                  for an initial phone consultation.
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6" xs="12">
            <br />
            <Card>
              <CardHeader>
                <h5>Frequently Asked Questions!</h5>
              </CardHeader>

              <CardBody>{faq_render}</CardBody>
            </Card>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <div
              ref={div => {
                this.survey_ref = div;
              }}
            >
              <iframe
                // src="https://stanforduniversity.qualtrics.com/jfe/form/SV_7QZU7CXFDAfyasR"
                src="https://redcap.stanford.edu/surveys/?s=DMLTAMNRX9"
                title="Redcap Survey"
                height="800px"
                width="100%"
              />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Babies;
