import React, { Component, Fragment } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem
} from "reactstrap";

import { Link } from "react-router-dom";

import ProjectsCarousel from "../ProjectsCarousel/ProjectsCarousel";

import "./Home.css";
import _ from "lodash";
import news_json from "../../json/news.json";
import moment from "moment";
import { FaAddressCard, FaPhone, FaUsers } from "react-icons/fa";
const news_items = news_json.items;

const pixel_art_lab_photo = require(`../../img/2022avatars.png`);
const human_lab_photo = require(`../../img/LabPhoto2023.jpg`);

class Home extends Component {
  render_news_by_months = news_items => {
    if (news_items.length === 0) {
      return <h5>No News Items Found</h5>;
    }

    const month_str_array = news_items.map(item => {
      return item.date;
    });

    const unique_month_strs = _.uniq(month_str_array);
    const unique_month_moments = unique_month_strs.map(ums => {
      return moment(ums, "MM-YYYY");
    });
    const sorted_unique_month_moments = _.reverse(
      _.sortBy(unique_month_moments)
    );

    const mapped_months = sorted_unique_month_moments.map(month => {
      const month_str = month.format("MM-YYYY");
      const matching_news = _.filter(news_items, { date: month_str });
      if (matching_news.length === 0) {
        return null;
      }
      return (
        <Fragment key={month}>
          <h4>{month.format("MMMM YYYY")}</h4>
          {this.render_news_items(matching_news)}
          <br />
        </Fragment>
      );
    });

    return mapped_months;
  };

  render_news_items = news_items => {
    // first, sort news items by date
    const sorted_items = _.sortBy(news_items, function(n) {
      return n.precedence;
    });

    return sorted_items.map(item => {
      let link_render = null;
      if (item.link && item.link !== "") {
        link_render = (
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            <Button outline color="primary">
              {item.link_text}
            </Button>
          </a>
        );
      }

      let image_render = null;
      if (item.images) {
        const mapped_images = item.images.map(image => {
          const image_src = require(`../../${image.src}`);
          return (
            <a href={image_src}>
              <img
                src={image_src}
                alt={image.alt}
                style={{ width: "100%", marginBottom: "5px" }}
              />
            </a>
          );
        });
        image_render = <div>{mapped_images}</div>;
      }

      return (
        <ListGroupItem key={item.copy}>
          <p>{item.copy}</p>
          {link_render}
          {image_render}
        </ListGroupItem>
      );
    });
  };

  render_news() {
    return (
      <ListGroup flush className="news-box">
        {this.render_news_by_months(news_items)}
      </ListGroup>
    );
  }

  render() {
    return (
      <Container>
        <br />
        <Row>
          <Col lg="7">
            <h2>Stanford Vision and Perception Neuroscience Lab</h2>
            <h4>PI: Dr. Kalanit Grill-Spector</h4>
            <p>
              Our research utilizes multimodal imaging (fMRI, dMRI, qMRI),
              computational modeling, and behavioral measurements to investigate
              human visual cortex. We seek to understand how the underlying
              neural mechanisms and their anatomical implementation enable rapid
              and efficient visual perception and cognition. Critically, we
              examine how brain and visual perception change across development
              to understand how the interplay between anatomical constraints and
              viewing experience shapes visual cortex and ultimately behavior.
            </p>
            <p>
              We strive to create a lab that reflects the diversity of our
              global community and is actively involved in solving scientific
              and societal problems that affect all of us. Please read our full
              statement <Link to="/ProspectiveMembers">here</Link>.
              Additionally, we have developed a lab expectations document to
              increase equity and success by clarifying the interpersonal and
              working responsibilities of all lab members. Check it out
              <a
                href="https://docs.google.com/document/d/1mR2Q4JEv_E1EcOBGvCz4mXo7sJWfPNJIho3sFtHIhoQ/edit?usp=sharing\"
                target="_blank\"
              >
                {" "}
                here
              </a>
              .
            </p>
            <Row>
              <Col lg="4" xs="12">
                <h6>
                  <FaAddressCard /> Street Address
                </h6>
                <span className="text-size-10">
                  Building 420 <br />
                  Stanford University <br />
                  Stanford, CA 94305-2130 <br />
                </span>
                <br />
              </Col>
              <Col lg="4" xs="12">
                <h6>
                  <FaPhone /> Phone Number
                </h6>
                <span className="text-size-10">(650) 723-8348</span> <br />
              </Col>
            </Row>
          </Col>
          <Col lg="5">
            <Row>
              <Col>
                <Link to="/People">
                  <h6>
                    <FaUsers /> Lab Members
                  </h6>
                </Link>
                <p>
                  <em>
                    <span className="text-size-10">
                      <strong>From left to right</strong>: Christina Tyagi, Karla Perez, 
                      Insub Kim, Juliet Horenziak, Ahmad Allen, Beth
                      Rispoli, Charleny Martinez Reyes, Kalanit Grill-Spector, Danya
                      Nicole Ortiz, Eline Kupers, Sophie Duhameau, Emily Kubota,
                      Peter Xu, Jewelia Yao. Not pictured here: Clara Bacmeister, Keithan Ducre, and Vaidehi Natu.                      
                      <br />
                    </span>
                  </em>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <img
                  src={human_lab_photo}
                  alt="photograph of lab members"
                  className="set-width"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg="7" xs="12">
            <h3>Research Questions</h3>
            <ProjectsCarousel />
          </Col>
          <Col lg="5" xs="12">
            <h3>Lab News</h3>
            {this.render_news()}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h3>Resources</h3>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <Card>
              <CardHeader>
                <strong>
                  Functional Localizer of Category-Selective Regions
                </strong>
              </CardHeader>
              <CardBody>
                Our lab developed an efficient functional localizer experiment
                to define category-selective cortical regions in human
                occipito-temporal cortex. You can download the experiment
                developed for Psychtoolbox{" "}
                <a
                  href="http://vpnl.stanford.edu/fLoc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Card>
              <CardHeader>
                <strong>
                  Cross-validated Cytoarchitectonic Atlas of the Ventral Stream
                </strong>
              </CardHeader>
              <CardBody>
                This atlas of 8 cytoarchitectonic regions of the human ventral
                stream developed by the Stanford Vision & Perception
                Neuroscience Lab in FreeSurfer as well as BrainVoyager file
                formats can be downloaded{" "}
                <a
                  href="http://vpnl.stanford.edu/vcAtlas"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Home;
