import React, { Component, Fragment } from "react";
import {
  Badge,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";

import "./Information.css";

const scope_colors = new Map([
  ["General", "success"],
  ["Stanford", "secondary"],
  ["VPNL", "info"],
  ["Graduate Programs", "warning"]
]);

class TopicModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
    this.topic = props.topic;
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  isLink = src => {
    if (src.startsWith("http")) {
      return true;
    }
    return false;
  };

  render() {
    const mapped_points = this.topic.points.map((point, point_idx) => {
      return (
        <li key={`point_${point_idx}`}>
          <div dangerouslySetInnerHTML={{ __html: point }} />
        </li>
      );
    });

    const modal = (
      <div>
        <Modal
          size="lg"
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>{this.topic.title}</ModalHeader>
          <ModalBody>
            <ul>{mapped_points}</ul>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );

    const { title, img_alt, img_src, scope } = this.props.topic;
    const img = this.isLink(img_src) ? img_src : require(`../../${img_src}`);

    return (
      <Fragment>
        <Button
          color="link"
          onClick={this.toggle}
          style={{ width: "100%", whiteSpace: "normal", color: "black" }}
        >
          <Card className="fixed-height-scroll">
            <CardImg
              top
              width="100%"
              src={img}
              alt={img_alt}
              style={{ opacity: "0.9" }}
            />
            <Badge
              color={scope_colors.get(scope) || "secondary"}
              className="image-badge"
            >
              {scope}
            </Badge>
            <CardBody>
              <CardTitle>{title}</CardTitle>
            </CardBody>
          </Card>
        </Button>
        {modal}
      </Fragment>
    );
  }
}

export default TopicModal;
