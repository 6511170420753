import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Nav,
  NavLink
} from "reactstrap";
import {
  FaUsers,
  FaHome,
  FaPenFancy,
  FaUserPlus,
  FaLink,
  FaLock,
  FaBrain,
  FaInfo
} from "react-icons/fa";

import "./MenuBar.css";
const logo_url = require(`../../img/vpnl_logo_gray.png`);

class MenuBar extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    const icon_size = "25px";
    return (
      <div>
        <Navbar dark className="cardinal-bg" expand="md">
          <LinkContainer to="/">
            <NavbarBrand>
              <img src={logo_url} className="logo" alt="lab logo" />
            </NavbarBrand>
          </LinkContainer>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <LinkContainer exact={true} to="/">
                <NavLink>
                  <FaHome size={icon_size} /> Home
                </NavLink>
              </LinkContainer>
              <LinkContainer to="/People">
                <NavLink>
                  <FaUsers size={icon_size} /> People
                </NavLink>
              </LinkContainer>
              {/* <LinkContainer to="/Research">
                <NavLink>Research</NavLink>
              </LinkContainer> */}
              <LinkContainer to="/Publications">
                <NavLink>
                  <FaPenFancy size={icon_size} /> Publications
                </NavLink>
              </LinkContainer>
              <LinkContainer to="/Participate">
                <NavLink>
                  <FaBrain size={icon_size} /> Participate
                </NavLink>
              </LinkContainer>
              <LinkContainer to="/ProspectiveMembers">
                <NavLink>
                  <FaUserPlus size={icon_size} /> Join the lab
                </NavLink>
              </LinkContainer>
              <LinkContainer to="/Information">
                <NavLink>
                  <FaInfo size={icon_size} /> Grad School Info
                </NavLink>
              </LinkContainer>
              <LinkContainer to="/Resources">
                <NavLink>
                  <FaLink size={icon_size} /> Resources
                </NavLink>
              </LinkContainer>

              <NavLink
                href="http://vpnl.stanford.edu/internal/wiki/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLock size={icon_size} /> Internal
              </NavLink>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default MenuBar;
