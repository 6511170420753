import React, { Component, Fragment } from "react";
import {
  Badge,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";

import TopicModal from "./TopicModal.js";

import grad_school_json from "../../json/information.json";

import Fuse from "fuse.js";
import _ from "lodash";
import "./Information.css";

const topics = grad_school_json.topics;
const scopes = _.uniq(
  topics.map(topic => {
    return topic.scope;
  })
);
const scope_colors = new Map([
  ["General", "success"],
  ["Stanford", "secondary"],
  ["VPNL", "info"],
  ["Graduate Programs", "warning"]
]);

const Checkbox = ({ type = "checkbox", name, checked = true, onChange }) => (
  <input type={type} name={name} checked={checked} onChange={onChange} />
);

class Information extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      checked_scopes: new Map(
        scopes.map(scope => {
          return [scope, true];
        })
      )
    };
  }

  handleChange = e => {
    const item = e.target.name;
    const is_checked = e.target.checked;
    this.setState(prevState => ({
      checked_scopes: prevState.checked_scopes.set(item, is_checked)
    }));
  };

  handleSearch = search_term => {
    this.setState({
      query: search_term
    });
  };

  trim_topics = allTopics => {
    // first, filter by search bar
    let search_results = allTopics;
    if (this.state.query !== "") {
      var options = {
        shouldSort: true,
        threshold: 0.2,
        location: 0,
        distance: 5000,
        maxPatternLength: 32,
        minMatchCharLength: 4,
        keys: ["title", "points"]
      };
      var fuse = new Fuse(allTopics, options);
      search_results = fuse.search(this.state.query);
    }

    // second, filter by scopes
    let valid_scopes = [];
    this.state.checked_scopes.forEach((is_checked, scope) => {
      if (is_checked) {
        valid_scopes.push(scope);
      }
    });

    return search_results.filter(res => valid_scopes.indexOf(res.scope) > -1);
  };

  render_topic = topic => {
    return (
      <Col key={topic.title} lg="3" sm="6" xs="12">
        <TopicModal topic={topic} />
      </Col>
    );
  };

  render_topics = ts => {
    if (ts.length === 0) {
      return <h5>No search results found</h5>;
    }

    const rendered_topics = ts.map(topic => {
      return this.render_topic(topic);
    });

    return (
      <Fragment>
        <hr />
        <Row>{rendered_topics}</Row>
      </Fragment>
    );
  };

  render() {
    const rendered_checkboxes = scopes.map(scope => {
      return (
        <Col lg="3" sm="4" xs="12" key={scope}>
          <h6>
            <Checkbox
              name={scope}
              checked={this.state.checked_scopes.get(scope)}
              onChange={this.handleChange}
            />
            {` `}
            <Badge color={scope_colors.get(scope) || "secondary"}>
              {scope}
            </Badge>
          </h6>
        </Col>
      );
    });

    return (
      <Container>
        <br />
        <Row>
          <Col>
            <h3>Curious About Graduate School?</h3>
            <p>
              Here, we’ve assembled some general questions you might have about
              pursuing a PhD in our lab or department:
            </p>
          </Col>
        </Row>
        <hr />

        <Row>
          <Col lg="8" xs="8">
            <Form>
              <FormGroup>
                <Label for="search_input">Search for information about:</Label>
                <Input
                  type="text"
                  id="search_input"
                  onChange={e => this.handleSearch(`${e.target.value}`)}
                  placeholder="e.g., housing"
                />
              </FormGroup>
              <hr />
              <FormGroup>
                <Label>I want to see information that applies to:</Label>
                <Row>{rendered_checkboxes}</Row>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>{this.render_topics(this.trim_topics(topics))}</Col>
        </Row>
      </Container>
    );
  }
}

export default Information;
