import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

import "./Footer.css";

class Footer extends Component {
  render() {
    const logo_url = require(`../../img/vpnl_logo_gray.png`);
    return (
      <Container>
        <hr />
        <Row>
          <Col lg="11">
            <p className="cardinal">
              <strong>
                Grill-Spector Vision & Perception Neuroscience Lab
              </strong>{" "}
              <br />
              Building 420 <br />
              Stanford University <br />
            </p>
          </Col>
          <Col lg="1" xs="2">
            <img src={logo_url} alt="lab logo" className="set-width" />
          </Col>
        </Row>
        <br />
      </Container>
    );
  }
}

export default Footer;
