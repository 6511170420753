import React, { Component } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";

import prospectives_json from "../../json/prospectives.json";
const positions = prospectives_json.positions;
const flag_src = require("../../img/diversity_flag.png");

class ProspectiveMembers extends Component {
  render_bullet_list = items => {
    return (
      <ul>
        {items.map(item => {
          return <li key={item}>{item}</li>;
        })}
      </ul>
    );
  };

  render_positions = positions => {
    const mapped_positions = positions.map(position => {
      let description_render = null;
      if (position.description) {
        description_render = (
          <p>
            <strong>About the position</strong>
            <br />
            <div dangerouslySetInnerHTML={{ __html: position.description }} />
          </p>
        );
      }

      let qualifications_render = null;
      if (position.qualifications.length > 0) {
        qualifications_render = (
          <div>
            <strong>Qualities of ideal candidates</strong>
            <br />
            {this.render_bullet_list(position.qualifications)}
          </div>
        );
      }

      let responsibilities_render = null;
      if (position.responsibilities.length > 0) {
        responsibilities_render = (
          <div>
            <strong>Responsibilities</strong>
            <br />
            {this.render_bullet_list(position.responsibilities)}
          </div>
        );
      }

      let commitment_render = null;
      if (position.commitment) {
        commitment_render = (
          <div>
            <strong>Commitment</strong>
            <br />
            {position.commitment}
            <br />
            <br />
          </div>
        );
      }

      return (
        <Col lg="12" xs="12" key={position.title}>
          <Card>
            <CardHeader>
              <h5>{position.title}</h5>
            </CardHeader>
            <CardBody>
              {description_render}
              {qualifications_render}
              {responsibilities_render}
              {commitment_render}
              <strong>How to apply</strong>
              <br />
              <div
                dangerouslySetInnerHTML={{
                  __html: position.application_details
                }}
              />
            </CardBody>
          </Card>
          <br />
        </Col>
      );
    });
    return mapped_positions;
  };

  render() {
    return (
      <Container>
        <br />
        <Row>
          <Col>
            <p>
              The Vision and Perception Neuroscience lab is committed to
              fostering a diverse and positive lab climate. We seek to recruit,
              support, and retain a diverse group of members, across gender
              identity, race, sexual orientation, backgrounds, beliefs, and
              experiences. In particular, we are dedicated to broadening the
              representation in STEM of individuals from groups that have
              historically been underrepresented in the sciences. We are
              committed to this goal by having regular (currently weekly)
              meetings to develop and execute action items to improve equity and
              inclusivity in the lab.{" "}
            </p>

            <p>
              We strive to create a lab that reflects the diversity of our
              global community and is actively involved in solving scientific
              and societal problems that affect all of us. As a team, we work to
              foster a learning and working environment where individuals feel
              welcome, safe, respected, and supported by holding each other to
              high standards of intrapersonal behavior and societal engagement.
              This is a dynamic process, and we are continuously improving our
              efforts toward these goals.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <img
              src={flag_src}
              style={{ width: "80%", marginLeft: "10%" }}
              alt="A series of ventral brain surfaces, each with a different background color. Different functional regions of interest are drawn, one per surface. The color of the region of interest mask matches the background color for that surface"
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h4>We're looking for...</h4>
          </Col>
        </Row>
        <Row>{this.render_positions(positions)}</Row>
      </Container>
    );
  }
}

export default ProspectiveMembers;
