import React, { Component } from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { FaLink } from "react-icons/fa";
import Slider from "react-slick";
import "./ProjectsCarousel.css";
import json_file from "../../json/research_questions.json";
const items = json_file.items;

export default class SimpleSlider extends Component {
  render() {
    const slides = items.map(item => {
      let caption_render = null;
      if (item.url) {
        caption_render = (
          <Card key={item.caption}>
            <CardHeader>
              <h5>{item.caption}</h5>
            </CardHeader>
            <CardBody>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <p>{item.description}</p>
                <Button color="primary" outline>
                  <FaLink /> Link{" "}
                </Button>
              </a>
            </CardBody>
          </Card>
        );
      } else {
        caption_render = (
          <Card>
            <CardBody>
              <h4>{item.caption}</h4>
              <p>{item.description}</p>
            </CardBody>
          </Card>
        );
      }

      return caption_render;
    });
    const settings = {
      dots: false,
      autoplay: true,
      autoplaySpeed: 6000, //ms
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true
    };
    return (
      <div>
        <Slider {...settings}>{slides}</Slider>
      </div>
    );
  }
}
